<template>
    <TransitionRoot as="template" :show="isLoginModalOpen">
        <Dialog as="div" class="relative z-10" @close="closeLoginModal">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex sm:items-center justify-center sm:min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                            <XIcon
                                class="block h-6 w-6 absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700"
                                @click="closeLoginModal"/>
                            <div class="min-h-full flex sm:items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                                <div class="max-w-md w-full space-y-4">
                                    <div>
                                        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{{ $t("login.title") }}</h2>
                                    </div>
                                    <form @submit="onSubmitLogin($event)">
                                        <div class="rounded-md shadow-sm -space-y-px">
                                            <div class="flex h-7 mb-3">
                                                <p v-if="error"
                                                   class="h-7 rounded-md text-red-600 text-sm w-full text-center bg-red-100 pt-1">
                                                    {{ errorValue() === '1' ? $t('login.error.wrong_signatures') : errorValue() === '2' ? $t('login.error.something_went_wrong') : ''}}</p>
                                            </div>

                                            <form-input id="username"
                                                        fieldName="username"
                                                        type="text"
                                                        autocomplete="username"
                                                        label="Username"
                                                        :placeholder="$t('login.username')"
                                                        v-model="username"
                                                        :required="true"
                                                        customClass="rounded-t-md"
                                            ></form-input>
                                            <form-input id="password"
                                                        fieldName="password"
                                                        type="password"
                                                        autocomplete="current-password"
                                                        label="Password"
                                                        :placeholder="$t('login.password')"
                                                        v-model="password"
                                                        :required="true"
                                                        customClass="rounded-b-md"
                                            ></form-input>
                                        </div>

<!--                                        <div class="flex items-center justify-between">-->
<!--                                            <div class="flex items-center">-->
<!--                                                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />-->
<!--                                                <label for="remember-me" class="ml-2 block text-sm text-gray-900"> {{$t("login.remember_me")}} </label>-->
<!--                                            </div>-->

<!--                                            <div class="text-sm">-->
<!--                                                <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> {{$t("login.forgot_password")}} </a>-->
<!--                                            </div>-->
<!--                                        </div>-->

                                        <div>
                                            <button type="submit"
                                                    class="mt-8 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
												<span class="absolute left-0 inset-y-0 flex items-center pl-3">
													<LockClosedIcon class="h-5 w-5 text-white group-hover:text-gray-200"
                                                                    aria-hidden="true"/>
												</span>
                                                {{$t("login.sign_in")}}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts">
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {LockClosedIcon} from '@heroicons/vue/solid'
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {defineComponent} from "vue";
import FormInput from "@/components/FormInput.vue";
import userActions from '../store/user/user-actions'
import {XIcon} from "@heroicons/vue/outline";

export default defineComponent({
    name: "login-modal",
    components: {
        XIcon,
        FormInput,
        Dialog,
        DialogPanel,
        TransitionRoot,
        TransitionChild,
        LockClosedIcon,
    },
    setup() {
        const store = useStore();
        const username = ref('');
        const password = ref('');
        const error = ref('');

        function closeLoginModal() {
            store.commit('OPEN_LOGIN_MODAL', false)
            clearLoginData()
            error.value = '';
        }

        function onSubmitLogin(e: any) {
            e.preventDefault();
            error.value = '';
            store.dispatch(userActions.LOGIN_USER, {
                username: username.value,
                password: password.value,
            }).then((response: string) => {
                error.value = response;
            })
            closeLoginModal()
        }
        function errorValue(){
            if(error.value === 'Wrong username and password combination!'){
                return '1'
            }else if (error.value === 'Something went wrong. Please try again later.'){
                return '2'
            }else {
                return ''
            }
        }

        function clearLoginData() {
            username.value = ''
            password.value = ''
        }


        return {
            isLoginModalOpen: computed(() => store.state.application.openLoginModal),
            closeLoginModal,
            username,
            password,
            onSubmitLogin,
            clearLoginData,
            error,
            errorValue,
        }
    }
});
</script>
