<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <header>
        <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
            <div class="w-full mx-auto px-2 sm:px-6 lg:px-8">
                <div class="relative flex items-center justify-between h-16">
                    <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <!-- Mobile menu button-->
                        <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span class="sr-only">Open main menu</span>
                            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </DisclosureButton>
                    </div>
                    <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div class="flex-shrink-0 flex items-center text-white font-medium">
                            IFRAME DEMO
                        </div>
                        <div class="hidden sm:block sm:ml-6 sm:px-5">
                            <div class="flex space-x-4">
                                <button v-for="item in navigation"
                                        :key="item.key"
                                        :class="[item.path === currentNavItem ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']"
                                        :aria-current="item.key === currentNavItem ? 'page' : undefined"
                                        @click="submitNavigationItem(item)"
                                >
                                    {{ $t(`header.${item.key}`)}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <language-dropdown />
                    <div class="flex flex-col-reverse sm:inset-auto sm:ml-6 sm:pr-0 sm:flex-row">
                        <div class="balance-container flex flex-col items-end sm:flex-row sm:items-center" v-if="isLogged">
                            <span class="text-gray-300 hidden sm:inline" v-if="userBalance">{{ $t("header.balance") }}</span>
                            <span class="text-white font-medium text-[11px] sm:text-xs sm:px-2">{{ userBalance }}</span>
                        </div>
                        <div class="guest-content hidden sm:block" v-if="!isLogged">
                            <button v-if="loginEnabled" class="text-white px-3 py-2 rounded-md text-sm font-medium mx-2 hover:bg-gray-700 hover:rounded-md" @click="openLoginModal">
                                {{ $t("header.login") }}
                            </button>
                            <button v-if="registerEnabled" class="text-white px-3 py-2 rounded-md text-sm font-medium mx-2 hover:bg-gray-700 hover:rounded-md" @click="openRegisterModal">
                                {{ $t("header.register") }}
                            </button>
                        </div>
                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-3">
                            <div v-if="isLogged">
                                <MenuButton class="bg-gray-800 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ml-12 sm:ml-0">
                                    <span class="sr-only">Open user menu</span>
                                    <UserIcon class="h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                                </MenuButton>
                            </div>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems v-if="isLogged" class="origin-top-right absolute right-0 mt-4 w-42 p-2 font-medium hover:bg-gray-200 hover:rounded-md rounded-md shadow-lg  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-slot="{ active }">
                                        <a class="cursor-pointer"
                                           :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                           @click="submitNavigationItem({ key: 'live-sports', path: `/my/betlist`})"
                                        >
                                            {{ $t("profile_dropdown.my_bets") }}
                                        </a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a class="cursor-pointer"
                                           :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                           @click="openMySportsbookModal()"
                                        >
                                            {{ $t("profile_dropdown.my_sportsbook") }}
                                        </a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a class="cursor-pointer" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                           @click="onLogout"
                                        >
                                            {{ $t("profile_dropdown.log_out") }}
                                        </a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <DisclosurePanel class="sm:hidden">
                <div class="px-2 pt-2 pb-3 space-y-1">
                    <div class="guest-content hidden: sm mb-5 pb-3 border-b">
                        <DisclosureButton v-if="!isLogged && loginEnabled" class="block px-3 py-2 rounded-md text-base font-medium text-white" @click="openLoginModal">
                            {{ $t("header.login") }}
                        </DisclosureButton>
                        <DisclosureButton v-if="!isLogged && registerEnabled" class="block px-3 py-2 rounded-md text-base font-medium text-white" @click="openRegisterModal">
                            {{ $t("header.register") }}
                        </DisclosureButton>
                    </div>

                    <DisclosureButton
                        v-for="item in navigation"
                        :key="item.key" as="a"
                        :class="[item.key === currentNavItem ? 'text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.key === currentNavItem ? 'page' : undefined"
                        @click="submitNavigationItem(item)"
                    >
                        {{ $t(`header.${item.key}`)}}
                    </DisclosureButton>
                </div>
            </DisclosurePanel>
        </Disclosure>
    </header>
</template>

<script lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, XIcon, UserIcon } from '@heroicons/vue/outline'
import {computed, defineComponent, ref} from "vue"
import Navigation from '../types/Navigation'
import {useStore} from "vuex";
import userActions from "@/store/user/user-actions";
import {sendPostMessage, siteConfig} from "@/composables/helpers";
import {SiteConfig} from "@/store/types/types";
import LanguageDropdown from "@/components/LanguageDropdown.vue";
export default defineComponent({
    name: 'MainHeader',
    components: {
        LanguageDropdown,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        MenuIcon,
        XIcon,
        UserIcon,
    },
    setup() {
        const config: SiteConfig = siteConfig();
        let navigation = ref<Navigation[]>([
            { key: 'home', path: '/euro/home'},
            { key: 'sports', path: '/euro/sport/soccer'},
            { key: 'live_betting', path: '/euro/live'},
        ])

        const store = useStore();
        let currentNavItem = computed(() => store.state.application.path)
        const isLogged = computed(() => store.state.user.isLogged);

		const registerEnabled = siteConfig().registerEnabled
		const loginEnabled = siteConfig().loginEnabled


        if(!isLogged.value) {
            navigation.value = navigation.value.filter(item => item.key !== 'my-bets')
        }

        function openLoginModal() {
            store.commit('OPEN_LOGIN_MODAL', true)
        }

        function openRegisterModal() {
            store.commit('OPEN_REGISTER_MODAL', true)
        }

        function onLogout() {
            store.dispatch(userActions.LOGOUT_USER)
        }

        function submitNavigationItem(navigationItem: Navigation) {
            const {path} = navigationItem;
            store.commit('SET_PATH', path)
            sendPostMessage('NAVIGATE_TO', path)
        }
        function openMySportsbookModal(){
            sendPostMessage('OPEN_MY_SPORTSBOOK_MODAL')
        }

        return {
            navigation,
            isLogged,
            openLoginModal,
            openRegisterModal,
            onLogout,
            userBalance: computed(() => store.state.user.balance),
            submitNavigationItem,
            currentNavItem,
            config,
            openMySportsbookModal,
			registerEnabled,
			loginEnabled,
        }
    }
});
</script>
