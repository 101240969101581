import { createI18n } from 'vue-i18n';
import en from './locales/en';
import de from './locales/de';
import it from './locales/it';
import es from './locales/es';
import dk from './locales/dk';
import nl from './locales/nl';
import sv from './locales/sv';
import br from "./locales/br";

const languages = {
    en,
    de,
    it,
    es,
    dk,
    nl,
    sv,
    br,
};
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: languages,
    messages: languages,
});
export default i18n;
