import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "truncate w-56 text-left sm:w-full" }
const _hoisted_2 = { class: "w-60 truncate text-left sm:w-full" }


import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import {Currency, Language, OddFormat} from '@/store/types/types'

interface Props {
    items: Currency[] | Language[] | OddFormat[],
    selectedItem: object |  null,
    generateLabel: any,
    defaultTitle: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDown',
  props: {
    items: {},
    selectedItem: {},
    generateLabel: {},
    defaultTitle: {}
  },
  emits: ['selectItem'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit
function selectItem(selectedItem: object | unknown) {
    emits('selectItem', selectedItem)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Menu), {
    as: "div",
    class: "relative sm:text-sm !mt-2 mb-0.5"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(MenuButton), {
        as: "div",
        class: "flex justify-between items-center rounded-md cursor-pointer text-center border border-gray-300 px-3 py-1.5 hover:bg-[#E8F0FE]"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedItem ? _ctx.generateLabel(_ctx.selectedItem) : _ctx.defaultTitle), 1),
          _cache[0] || (_cache[0] = _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            "stroke-width": "1.5",
            stroke: "currentColor",
            class: "w-4 h-4 ml-3"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "m19.5 8.25-7.5 7.5-7.5-7.5",
              class: "stroke-current"
            })
          ], -1))
        ]),
        _: 1
      }),
      _createVNode(_unref(MenuItems), { class: "flex flex-col absolute w-full h-36 overflow-y-scroll bg-white rounded-md border border-gray-300 shadow-lg z-10 top-10" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createBlock(_unref(MenuItem), {
              as: "div",
              key: index,
              onClick: ($event: any) => (selectItem(item)),
              class: "flex px-3 py-1.5 rounded-md cursor-pointer hover:bg-[#E8F0FE]"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.generateLabel(item)), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})