<template>
	<div class="form-input-wrapper">
		<label :for="id" class="sr-only">{{ label }}</label>
		<input :id="id"
               :name="fieldName"
               :type="type"
               :autocomplete="autocomplete"
               class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
               :class="customClass"
               :placeholder="placeholder"
               :value="modelValue"
               v-model="formInputField"
               :required="required"
		/>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
export default defineComponent({
	name: "form-input",
	props: {
		id: {
			type: String,
			default: '',
		},
		fieldName: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		autocomplete: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
        customClass: {
            types: String,
            default: '',
        }
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		let formInputField = computed({
			get: () => {
				return props.modelValue
			},
			set: (value) => {
				emit('update:modelValue', value)
			}
		})

		return {
			formInputField,
		}
	}
});
</script>