<template>
    <div class="language-dropdown relative text-white px-3 py-2 text-sm font-medium hover:bg-gray-700 hover:rounded-md">
        <div @click="toggleLanguagesDropdown" class="flex cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
            </svg>
            <span class="px-1.5">{{selectedLanguage}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 pt-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" class="stroke-current"/>
            </svg>
        </div>
        <div @click="toggleLanguagesDropdown" class="w-full h-full fixed top-0 left-0 z-10" v-if="isDropdownOpen"></div>
        <div :class="[isDropdownOpen ? 'block' : 'hidden']" class="absolute bg-white rounded-md shadow-lg z-20 top-12 left-0 p-1">
            <div
                v-for="(language, index) in languagesList"
                :key="index"
                class="px-4 py-2 text-gray-800 hover:bg-gray-200 hover:rounded-md cursor-pointer"
                @click="handleLanguageClick(language)"
            >{{ language.title }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue"
import { Language, SiteConfig } from "@/store/types/types";
import { siteConfig } from "@/composables/helpers";
import { useStore } from "vuex";
import i18n from "@/i18n.config";

export default defineComponent({
	name: "LanguageDropdown",
	setup() {
		const store = useStore()
		const isDropdownOpen = ref(false);
		const selectedLanguage = ref(localStorage.getItem("selectedLanguage") || 'EN');
		const languagesList = siteConfig().languagesList ?? [] as SiteConfig['languagesList'];

		function toggleLanguagesDropdown(){
			isDropdownOpen.value = !isDropdownOpen.value;
		}

		function updateLanguageSettings(language: Language) {
			const frameContent = document.getElementById('sportsbookcontainer') as HTMLIFrameElement | null;
			if (frameContent !== null) {
				frameContent.src = `${siteConfig().sportsBookUrl}${language.path}`;
			}
			selectedLanguage.value = language.path.slice(1).toUpperCase();
			store.commit('SET_SELECTED_LANGUAGE', language.path.slice(1));
			localStorage.setItem("selectedLanguage", language.path.slice(1));
			i18n.global.locale = language.path.slice(1);
		}

		function handleLanguageClick(language: Language) {
			isDropdownOpen.value = false;
			updateLanguageSettings(language);
		}

		watch(() => store.state.user.selectedLanguage, (newLanguage) => {
			if (newLanguage) {
				const languageToUpdate = languagesList.find(lang => lang.path.slice(1) === newLanguage);
				if (languageToUpdate) {
					selectedLanguage.value = languageToUpdate.path.slice(1).toUpperCase();
					updateLanguageSettings(languageToUpdate);
				}
			}
		}, { immediate: true });

		return {
			languagesList: siteConfig().languagesList,
			toggleLanguagesDropdown,
			handleLanguageClick,
			isDropdownOpen,
			selectedLanguage,
		}
	}
})
</script>
