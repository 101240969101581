<template>
	<div class="main-wrapper">
		<main-header @open-login-modal="openLoginModal" @open-register-modal="openRegisterModal"></main-header>
		<div id="main" class="w-full">
			<iframe id="sportsbookcontainer"
					:src="sportsBookUrl"
					data-frame-id="ds-sport-component"
					class="w-full md:min-h-screen"
                    :class="{ 'h-full': !sportsbookDynamicHeight}"
                    @load="onIframeLoad"
			></iframe>
		</div>
		<footer class="h-16 w-full dark:border-gray-800 bg-gray-800"/>
		<login-modal :showLoginModal="showLoginModal"></login-modal>
		<register-modal :showRegisterModal="showRegisterModal"></register-modal>
	</div>
</template>

<script lang="ts">
import { watch, defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { getDomain, handlePostMessage, siteConfig } from "@/composables/helpers";
import MainHeader from './components/MainHeader.vue'
import userActions from './store/user/user-actions'
import LoginModal from "@/components/LoginModal.vue";
import RegisterModal from "@/components/RegisterModal.vue";

export default defineComponent({
	name: 'App',
	components: {
		LoginModal,
        RegisterModal,
        MainHeader,
	},
	setup() {
		const store = useStore()
		const showLoginModal = ref(false)
		const showRegisterModal = ref(false)
        const sbToken = computed(() => store.state.user.sbToken)
        const jwt = computed(() => store.state.user.jwt)
        const currentYear = new Date().getFullYear()

        // eslint-disable-next-line
        let intervalId:any;
        watch(sbToken, (nextValue) => {
            if(siteConfig().useParam) {
                setParam(nextValue)
            } else {
                setCookie(nextValue)
				localStorage.setItem('sbToken', nextValue)
            }
        })

        watch(jwt, (value) => {
            if(value) {
                localStorage.setItem('sbJWT', value)
            } else {
                localStorage.removeItem('sbJWT')
                store.commit('IS_LOGGED', false)
            }
        })


        if(jwt.value) {
            store.commit('IS_LOGGED', true)
            intervalId = setInterval(getBalance, 4000)
        }

        if(!jwt.value) {
            store.dispatch(userActions.LOGOUT_USER)
        }

        function onIframeLoad() {
            const iframe = document.getElementById("sportsbookcontainer") as HTMLIFrameElement | null;
            setTimeout(() => {
				setCasinoMiniGames(iframe)
			}, 1000)
        }

        function setCasinoMiniGames(iframe: HTMLIFrameElement | null) {
            const gamesContent = siteConfig().miniGames
            const message = {
                type: 'SET_EXTERNAL_MINI_GAMES',
                payload: {
                    content: gamesContent
                },
            }

            if(iframe && iframe.contentWindow) {
                iframe.contentWindow?.postMessage(JSON.stringify(message), '*')
                console.log(message)
            }
        }

        function setParam(value: string) {
            const frameContent = document.getElementById('sportsbookcontainer') as HTMLIFrameElement | null
            if(!value) {
                store.commit('IS_LOGGED', false)
                clearInterval(intervalId)
                if(frameContent !== null) {
                    frameContent.src = `${siteConfig().sportsBookUrl}/en?sb=logout`
                }
            } else {
                if(frameContent !== null) {
                    frameContent.src += `?sb=${value.trim()}`
                }
                store.commit('OPEN_LOGIN_MODAL', false)
                store.commit('IS_LOGGED', true)
                intervalId = setInterval(getBalance, 4000)
            }
        }

        function setCookie(value: string) {
            const oneDayLater = new Date().getTime() + (86400000)
            const expiration = new Date(oneDayLater).toUTCString()
            if(!value) {
                document.cookie = `sb=; domain=${getDomain()}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
                store.commit('IS_LOGGED', false)
                clearInterval(intervalId)
            } else {
                document.cookie = `sb=${value.trim()}; domain=${getDomain()}; expires=${expiration}; path=/;`
                store.commit('OPEN_LOGIN_MODAL', false)
                store.commit('IS_LOGGED', true)
                intervalId = setInterval(getBalance, 4000)
            }
            const frameContent = document.getElementById('sportsbookcontainer') as HTMLIFrameElement | null
            if(frameContent !== null) {
                frameContent.src += ''
            }
        }

		function getBalance() {
			store.dispatch(userActions.GET_BALANCE, {
                sbToken: sbToken.value,
                jwt: jwt.value,
            })
		}

		function openLoginModal() {
            store.commit('OPEN_LOGIN_MODAL', true)
        }

        function openRegisterModal() {
            store.commit('OPEN_REGISTER_MODAL', true)
        }

        onMounted(() => {
            window.addEventListener('message', handlePostMessage)
        })

        onUnmounted(() => {
            window.removeEventListener('message', handlePostMessage)
        })

		return {
			isLogged: computed(() => store.state.user.isLogged),
			sportsBookUrl: `${siteConfig().sportsBookUrl}`,
            sportsbookDynamicHeight: siteConfig().sportsbookDynamicHeight,
			showLoginModal,
            openLoginModal,
            showRegisterModal,
            openRegisterModal,
            onIframeLoad,
            currentYear,
		}
	}
});
</script>
