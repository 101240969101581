<template>
    <Menu as="div" class="relative sm:text-sm !mt-2 mb-0.5">
        <MenuButton
            as="div"
            class="flex justify-between items-center rounded-md cursor-pointer text-center border border-gray-300 px-3 py-1.5 hover:bg-[#E8F0FE]"
        >
            <span class="truncate w-56 text-left sm:w-full">
                {{ selectedItem ? generateLabel(selectedItem) : defaultTitle }}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" class="stroke-current"/>
            </svg>
        </MenuButton>
        <MenuItems class="flex flex-col absolute w-full h-36 overflow-y-scroll bg-white rounded-md border border-gray-300 shadow-lg z-10 top-10">
            <MenuItem
                as="div"
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
                class="flex px-3 py-1.5 rounded-md cursor-pointer hover:bg-[#E8F0FE]">
                <span class="w-60 truncate text-left sm:w-full">
                    {{ generateLabel(item) }}
                </span>
            </MenuItem>
        </MenuItems>
    </Menu>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import {Currency, Language, OddFormat} from '@/store/types/types'

interface Props {
    items: Currency[] | Language[] | OddFormat[],
    selectedItem: object |  null,
    generateLabel: any,
    defaultTitle: string,
}

const props = defineProps<Props>()

const emits = defineEmits(['selectItem'])
function selectItem(selectedItem: object | unknown) {
    emits('selectItem', selectedItem)
}
</script>
