import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "language-dropdown relative text-white px-3 py-2 text-sm font-medium hover:bg-gray-700 hover:rounded-md" }
const _hoisted_2 = { class: "px-1.5" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleLanguagesDropdown && _ctx.toggleLanguagesDropdown(...args))),
      class: "flex cursor-pointer"
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        class: "w-5 h-5"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
        })
      ], -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectedLanguage), 1),
      _cache[3] || (_cache[3] = _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        class: "w-4 h-4 pt-1"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "m19.5 8.25-7.5 7.5-7.5-7.5",
          class: "stroke-current"
        })
      ], -1))
    ]),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleLanguagesDropdown && _ctx.toggleLanguagesDropdown(...args))),
          class: "w-full h-full fixed top-0 left-0 z-10"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.isDropdownOpen ? 'block' : 'hidden'], "absolute bg-white rounded-md shadow-lg z-20 top-12 left-0 p-1"])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languagesList, (language, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "px-4 py-2 text-gray-800 hover:bg-gray-200 hover:rounded-md cursor-pointer",
          onClick: ($event: any) => (_ctx.handleLanguageClick(language))
        }, _toDisplayString(language.title), 9, _hoisted_3))
      }), 128))
    ], 2)
  ]))
}