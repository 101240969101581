import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed z-10 inset-0 overflow-y-auto" }
const _hoisted_2 = { class: "flex sm:items-center justify-center sm:min-h-full p-4 text-center sm:p-0" }
const _hoisted_3 = { class: "min-h-full flex sm:items-center justify-center py-12 px-4 sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "max-w-md w-full space-y-4" }
const _hoisted_5 = { class: "mt-6 text-center text-3xl font-extrabold text-gray-900" }
const _hoisted_6 = { class: "rounded-md shadow-sm -space-y-px" }
const _hoisted_7 = { class: "flex h-7 mb-3" }
const _hoisted_8 = {
  key: 0,
  class: "h-7 rounded-md text-red-600 text-sm w-full text-center bg-red-100 pt-1"
}
const _hoisted_9 = {
  type: "submit",
  class: "mt-8 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}
const _hoisted_10 = { class: "absolute left-0 inset-y-0 flex items-center pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_LockClosedIcon = _resolveComponent("LockClosedIcon")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.isLoginModalOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "relative z-10",
        onClose: _ctx.closeLoginModal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_XIcon, {
                        class: "block h-6 w-6 absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700",
                        onClick: _ctx.closeLoginModal
                      }, null, 8, ["onClick"]),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("login.title")), 1)
                          ]),
                          _createElementVNode("form", {
                            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmitLogin($event)))
                          }, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                (_ctx.error)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.errorValue() === '1' ? _ctx.$t('login.error.wrong_signatures') : _ctx.errorValue() === '2' ? _ctx.$t('login.error.something_went_wrong') : ''), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createVNode(_component_form_input, {
                                id: "username",
                                fieldName: "username",
                                type: "text",
                                autocomplete: "username",
                                label: "Username",
                                placeholder: _ctx.$t('login.username'),
                                modelValue: _ctx.username,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                                required: true,
                                customClass: "rounded-t-md"
                              }, null, 8, ["placeholder", "modelValue"]),
                              _createVNode(_component_form_input, {
                                id: "password",
                                fieldName: "password",
                                type: "password",
                                autocomplete: "current-password",
                                label: "Password",
                                placeholder: _ctx.$t('login.password'),
                                modelValue: _ctx.password,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                                required: true,
                                customClass: "rounded-b-md"
                              }, null, 8, ["placeholder", "modelValue"])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("button", _hoisted_9, [
                                _createElementVNode("span", _hoisted_10, [
                                  _createVNode(_component_LockClosedIcon, {
                                    class: "h-5 w-5 text-white group-hover:text-gray-200",
                                    "aria-hidden": "true"
                                  })
                                ]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("login.sign_in")), 1)
                              ])
                            ])
                          ], 32)
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["onClose"])
    ]),
    _: 1
  }, 8, ["show"]))
}